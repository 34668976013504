<template>
    <div>
        <div class="loading-spinner" v-if="show">
            <div class="loading-spinner-div" style="margin-top:auto;height:100%">
                <fulfilling-bouncing-circle-spinner :animation-duration="4000" :size="60" :color="$root.colorLoading" />
            </div>
        </div>
        <b-card>
            <h5 class="fontWeightBold mb-4">مالی</h5>
            <b-table v-if="items.data" responsive striped outlined hover :fields="table_fields"
                :items="items.data.data">
                <template v-slot:cell(created_at)="data">
                    {{ data.item.created_at | persianDate }}
                </template>
                <template v-slot:cell(total)="data">
                    {{data.item.total | price}}
                </template>
                <template v-slot:cell(provides)="data">
                    <span v-if="data.item.provides != []">
                        <span v-for="(provide, index) in data.item.provides" :key="index">{{provide.id}}
                            {{ index + 1 != data.item.provides.length ? ' ، ' : '' }}</span>
                    </span>
                </template>
                <template v-slot:cell(seller)="data">
                    {{ data.item.seller ? data.item.seller.name + ' ' + data.item.seller.last_name + ' - شرکت : ' + data.item.seller.company_name: '' }}
                </template>
                <template v-slot:cell(status)="data">
                    <span v-if="data.item.status == 'pending'" class="badge badge-warning">پرداخت نشده</span>
                    <span v-else-if="data.item.status == 'completed'" class="badge badge-success">پرداخت شده</span>
                    <span v-else-if="data.item.status == 'canceled'" class="badge badge-danger">کنسل شده</span>
                </template>
                <template v-slot:cell(details)="data">
                    <button @click="showItem(data.item)" class="btn btn-info">
                        <i class="fa fa-info"></i>
                    </button>
                </template>
            </b-table>
            <div class="alert alert-danger text-right" v-if="items.data && items.data.data == ''">
                <span class="text-right">موردی یافت نشد ! </span>
            </div>
            <pagination :limit="2" v-if="items.data && !search" :data="items.data" @pagination-change-page="loadItems">
            </pagination>
        </b-card>
        <b-modal id="show-modal" hide-footer title="جزئیات" size="lg">
            <div v-if="editItemValue">
                <div class=" py-1">
                    <span class="fontWeightBold">کد پیگیری : </span>
                    <span>{{editItemValue.tracking_code != null ? editItemValue.tracking_code :'-'}}</span>
                </div>
                <div class="py-1">
                    <span class="fontWeightBold">وضعیت : </span>
                    <span v-if="editItemValue.status == 'completed'" class="badge badge-success">پرداخت شده</span>
                    <span v-if="editItemValue.status == 'pending'" class="badge badge-warning">پرداخت نشده</span>
                    <span v-if="editItemValue.status == 'canceled'" class="badge badge-danger">کنسل شده</span>
                </div>
                <div class=" py-1">
                    <span class="fontWeightBold">توضیحات : </span>
                    <span>{{editItemValue.description}}</span>
                </div>
                <b-table responsive striped hover class="mt-4" :fields="provide_details_fields"
                    :items="editItemValue.provides">
                    <template v-slot:cell(created_at)="data">
                        {{data.item.created_at | persianDate}}
                    </template>
                    <template v-slot:cell(seller_amount)="data">
                        {{data.item.pivot.seller_amount | price}}
                    </template>
                    <template v-slot:cell(product)="data">
                        <button @click="showProvide(data.item)" class="btn btn-info">
                            اقلام
                        </button>
                    </template>
                </b-table>
            </div>
        </b-modal>
        <b-modal id="provide-modal" title="اقلام" hide-footer size="lg">
            <div v-if="provide_details">
                <b-table responsive striped hover :fields="fields" :items="provide_details">
                    <template v-slot:cell(product)="data">
                        <p style="max-width: 500px;">
                            {{ data.item.variety ? data.item.variety.fullTitle :'' }}
                        </p>
                    </template>
                    <template v-slot:cell(available_count)="data">
                        {{ data.item.pivot ? data.item.pivot.available_count :'' }}
                    </template>
                    
                </b-table>
            </div>
        </b-modal>
    </div>
</template>
<script>
    import mixins from './mixins/mixins'
    export default {
        mixins: [mixins],
        data() {
            return {
                title: 'مالی',
                url: '/api/seller/pay_offs',
                table_fields: [{
                        key: 'id',
                        label: 'شناسه'
                    },
                    {
                        key: 'tracking_code',
                        label: 'کد پیگیری'
                    },
                    {
                        key: 'status',
                        label: 'وضعیت'
                    },
                    {
                        key: 'total',
                        label: 'مبلغ پرداختی (تومان)'
                    },
                    {
                        key: 'provides',
                        label: 'شناسه سفارش ها'
                    },
                    {
                        key: 'created_at',
                        label: 'تاریخ'
                    },
                    {
                        key: 'details',
                        label: 'جزئیات'
                    },
                ],
                provide_details_fields: [{
                        key: 'select',
                        label: ''
                    },
                    {
                        key: 'id',
                        label: 'شناسه'
                    },
                    {
                        key: 'invoice_id',
                        label: 'شناسه سفارش'
                    },
                    {
                        key: 'seller_amount',
                        label: 'مبلغ (تومان)'
                    },
                    {
                        key: 'created_at',
                        label: 'تاریخ'
                    },
                    {
                        key: 'product',
                        label: ''
                    },
                ],
                fields: [{
                        key: 'id',
                        label: 'شناسه'
                    },
                    {
                        key: 'product',
                        label: 'محصول'
                    },
                    {
                        key: 'available_count',
                        label: 'تعداد ارسال'
                    },
                ],
                provide_details: {}
            }
        },
        mounted() {
            this.loadItems()
        },
        methods: {
            showProvide(item) {
                this.provide_details = item.orders
                this.$root.$emit('bv::show::modal', 'provide-modal')
            },
        }
    }
</script>